@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;700&family=Heebo:wght@400;800");

.barlowregular {
  font-weight: 400;
  font-family: "Barlow", sans-serif;
}
.barlowmedium {
  font-weight: 500;
  font-family: "Barlow", sans-serif;
}
.barlowbold {
  font-family: "Barlow", sans-serif;
  font-weight: 700;
}
.heeboregular {
  font-family: "Heebo", sans-serif;
  font-weight: 400;
}
.heeboextrabold {
  font-family: "Heebo", sans-serif;
  font-weight: 800;
}
.f22 {
  font-size: 22px;
}
.f18 {
  font-size: 18px;
}
.f15 {
  font-size: 15px;
}
.mundotitle {
  font-size: 60px;
  color: white;
  text-align: center;
  line-height: 60px;
}

@media (max-width: 500px) {
  .mundotitle {
    font-size: 40px;
    line-height: 50px;
  }
}
.mundosubtitle {
  color: white;
  font-size: 36px;
}
.mundotext {
  color: white;
  font-size: 28px;
}
.productotitle {
  font-size: 35px;
  color: black;
  line-height: 75px;
  position: relative;
}
.producto-title-shadow {
  font-size: 35px;
  color: black;
  line-height: 75px;
  position: relative;
  text-shadow: 2px 2px 4px #000000;
}
.bgproductos,
.bgproductos3 {
  background-size: cover;
  background-position: center center;
  min-height: 70vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bgproductos2 {
  background-size: cover;
  background-position: right;
  min-height: 70vh;
  height: 100%;
}

@media (max-width: 768px) {
  .bgproductos {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-size: cover;
    background-position: center;
    min-height: 70vh;
    overflow-x: hidden;
    background-repeat: no-repeat;
    background-position: center !important;
  }

  .title-product {
    order: 0;
    width: 100%;
    margin-top: 20px;
    padding-right: inherit !important;
  }

  .image-light {
    margin-top: 30px;
    text-align: center;
  }

  .product-image {
    margin-top: 0px;
    width: 100%;
    margin-right: -200px;
  }

  .social-media {
    order: 3;
    width: 100%;
    margin-top: 20px;
  }
}
.social-media span {
  margin-right: 8px;
}

.imgproductlist {
  height: 250px;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
}

.product-slide {
  overflow: hidden;
}
.productslist {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cursorpointer {
  cursor: pointer;
}
.bggrey {
  background-color: #e3dcd8;
}
.bggrey2 {
  background-color: #dfdacd;
}
.bgwhite {
  background-color: white;
}
.red {
  color: #ea0000;
}
.brown {
  color: #d8ad89;
}

/* MUNDO TEQUILA */
.mundo-tequila {
  position: relative;
  background-image: url(./assets/mundo-tequila.jpg);
  height: 80vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  transition: background-color 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mundo-tequila::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
}

.mundo-tequila:hover::before {
  opacity: 0.5;
}

.mundo-tequila:hover .overlay {
  opacity: 0.7;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
}

.submundo-tequila {
  opacity: 0;
}

.mundo-tequila:hover .submundo-tequila {
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mundotext {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  line-height: 1.2;
  padding: 0 20px;
  font-size: 1.5rem;
  padding-top: 20px;
  padding-bottom: 10px;
  min-height: 119px;
}

.mundo-tequila .mundotitle,
.mundo-tequila .mundosubtitle,
.mundo-tequila .mundotext,
.mundo-tequila button {
  color: #ffffff !important;
}

@media (max-width: 768px) {
  .mundotitle,
  .mundosubtitle {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .link-worlds {
    display: flex !important;
    justify-content: center !important;
  }
  .nav-link {
    font-size: 26px;
  }
  .bg-nuestrosproductos {
    background-position: left center !important;
  }
}
/* MUNDO COCTELERIA */
.mundo-cocteleria {
  position: relative;
  background-image: url(./assets/mundo-cocteleria.jpg);
  height: 80vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  transition: background-color 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mundo-cocteleria::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
}

.mundo-cocteleria:hover::before {
  opacity: 0.5;
}

.mundo-cocteleria:hover .overlay {
  opacity: 0.7;
}

.content {
  position: relative;
  z-index: 2;
}

.submundo-cocteleria {
  opacity: 0;
}

.mundo-cocteleria:hover .submundo-cocteleria {
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mundo-cocteleria .mundotitle,
.mundo-cocteleria .mundosubtitle,
.mundo-cocteleria .mundotext,
.mundo-cocteleria button {
  color: #ffffff !important;
}

.button-insta {
  width: 250px;
  height: 60px;
}
.icoinsta {
  max-height: 40px;
}
body {
  font-family: "Heebo" !important;
  background-color: white !important;
  color: black !important;
}
header {
  margin-bottom: 86px;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track-piece {
  background-color: #262626;
}
::-webkit-scrollbar-thumb:vertical {
  height: 30px;
  background-color: black;
}
.logo {
  width: 280px;
}
.footer {
  background-color: #919599;
  color: white;
}
.container-search {
  width: fit-content;
  position: relative;
}
.search-input {
  background-color: #eee !important;
  padding-left: 10px;
  margin-left: 20px;
  max-width: 180px;
  min-width: max-content;
  min-height: 30px;
  font-size: 12px !important;
  border: 0 !important;
}
.icolupa {
  padding: 1px 1px 1px 1px;
  max-width: 15px;
}
.searchbar-button {
  border: none;
  background: transparent;
  width: 10px;
  height: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
}
.btnlang {
  border-radius: 0px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: inherit !important;
  color: #616161;
  font-weight: 100;
}
.icolang {
  margin-bottom: 3px;
  width: 16px;
}
h1 {
  font-weight: bold !important;
  margin-bottom: 0px !important;
}
.h1_50 {
  font-size: 50px !important;
}
.h1_55 {
  font-size: 55px !important;
}
.h1_75 {
  font-size: 75px !important;
}
h2 {
  letter-spacing: 4px;
  margin-top: -6px !important;
}
.h2_40 {
  font-size: 40px !important;
}
.h2_43 {
  font-size: 46px !important;
}
.txt-main {
  font-size: 28px;
  padding-right: 250px;
}
button {
  border-radius: 25px !important;
  font-weight: bold;
  border: 0;
  background-color: #585650;
  color: white;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
}
button.animation {
  -webkit-animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
    both;
  animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both;
}
.button-black {
  background-color: black;
}
button:hover {
  /* background-color: #e30613; */
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}
.txt-animation {
  -webkit-animation: swing-in-top-fwd 3s cubic-bezier(0.175, 0.885, 0.32, 1.275)
    both;
  animation: swing-in-top-fwd 3s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}
.button-no-style {
  animation: none !important;
}

@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}
@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}
@-webkit-keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

/* CUSTOM LINK MÁS INFO HOMEPAGE  */
.custom-link {
  display: inline-block;
  border-radius: 25px;
  font-weight: bold;
  background-color: #585650;
  color: white;
  padding: 10px 60px;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.3s ease-in-out;
}

.custom-link.button-black {
  background-color: black;
}

.custom-link:hover {
  background-color: #e30613;
}

.custom-link:hover {
  color: white;
}

input {
  width: 100%;
  border: 0;
  border-radius: 4px;
  min-height: 40px;
  padding-left: 6px;
}
textarea {
  width: 75%;
  border: 0;
  border-radius: 4px;
  min-height: 90px;
  padding-left: 6px;
}
.button-lightgrey {
  background-color: #dfdacd;
}
.button-product {
  text-decoration: none;
  color: white;
}
.title-rwss {
  font-size: 50px;
}
.ico-rrss {
  width: 32px;
  height: 32px;
  margin-left: 20px;
  margin-bottom: 11px;
}
.semitransp {
  opacity: 0.9;
}
.languages {
  width: 100%;
  min-width: 100px;
  background-color: #b09e96;
  color: white;
  z-index: 1000;
  border-radius: 20px;
  text-align: center;
}
.languages.active {
  display: block !important;
}
.languages div {
  border-bottom: 1px solid white;
  padding-bottom: 4px;
  padding-top: 4px;
  cursor: pointer;
}
.languages div:first-child {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.languages div:last-child {
  border-bottom: 0;
  padding-bottom: 4px;
  padding-top: 4px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.languages div:hover {
  background-color: white;
  color: #6a5145;
}
ul a {
  text-decoration: none !important;
}
.nav-item {
  color: black;
}
.nav-item:hover {
  color: #e30613;
}
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler:hover {
  background-color: transparent;
  outline: none;
}

/*scroll button*/
.top-to-btm {
  position: relative;
}
.icon-position {
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
}
.icon-style {
  background-color: #551b54;
  border: 2px solid #fff;
  height: 42px;
  width: 42px;
  color: #fff;
  cursor: pointer;
  animation: movebtn 3s ease-in-out infinite;
  transition: all 0.5s ease-in-out;
}
.icon-style:hover {
  animation: none;
  background: #fff;
  color: black;
  border: 2px solid black;
}

.header-ladestileria {
  position: relative;
  background-position: center center;
  color: #585650;
  font-family: barlow;
  height: 100vh;
  overflow: hidden;
}

.video-background {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.bullets-ladestileria {
  font-size: 35px;
  font-weight: 700;
}
.bullets-ladestileria .number {
  color: white;
  margin-right: 50px;
  width: 35px;
}
.bg-nuestrosmundos {
  background-image: url(./assets/nuestros-mundos.svg);
  background-repeat: repeat-y;
  background-size: contain;
  background-position: center center;
  min-height: 350px;
  background-attachment: fixed;
}
.bg-nuestrosproductos {
  background-image: url(./assets/ladestileria-nuestrosproductos.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
  background-attachment: fixed;
}
.bg-jalisco {
  background-image: url(./assets/ladestileria-jalisco.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 650px;
  -webkit-animation: kenburns-bottom 24s ease-out infinite both;
  animation: kenburns-bottom 24s ease-out infinite both;
}
.bg-nuestroequipo {
  background-image: url(./assets/ladestileria-nuestroequipo.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.bg-politicaalimentaria {
  background-image: url(./assets/ladestileria-politicaalimentaria.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 650px;
  background-attachment: fixed;
}
.ico-pdf {
  width: 28px;
}
/** PRODUCTOS **/
.logobrand {
  max-width: 160px;
}
.bg-nuestrosprod {
  position: relative;
  background-image: url(./assets/productos-nuestrasmarcas.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
  overflow: hidden;
  transition: background-color 0.3s ease-in-out transform 0.3s;
}

.s1,
.s2,
.s3 {
  transition: ease-in-out transform 0.3s;
}

.s1:hover {
  transform: scale(0.95);
}
.s2:hover {
  transform: scale(0.8);
}
.s3:hover {
  transform: scale(0.9);
}

.bg-nuestrosprod::after {
  position: absolute;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.bg-nuestrosprod::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
}
.bg-nuestrosprod:hover::before {
  opacity: 1;
}
.orange::before {
  background-color: #d8ad89;
}
.gray::before {
  background-color: #cfc9b5;
}
.blue::before {
  background-color: #b7bbbf;
}
.hover-text {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  color: #000;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
}
@media (max-width: 767px) {
  .hover-text.lateral-left {
    left: 15%;
  }

  .hover-text.lateral-right {
    left: 80%;
  }

  .hover-text.middle {
    left: 43%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hover-text.lateral-left {
    left: 10%;
  }

  .hover-text.lateral-right {
    left: 85%;
  }

  .hover-text.middle {
    left: 45%;
  }
}

@media (min-width: 992px) {
  .hover-text.lateral-left {
    left: 12%;
  }

  .hover-text.lateral-right {
    left: 80%;
  }

  .hover-text.middle {
    left: 44%;
  }
}
.bg-alcoholicos {
  background-image: url(./assets/productos-alcoholicos.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.bg-noalcoholicos {
  background-image: url(./assets/productos-noalcoholicos.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.bg-vinos {
  background-image: url(./assets/productos-vinos.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.bg-mundotequila {
  background-image: url(./assets/productos-mundotequila.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}
.bg-mundococteleria {
  background-image: url(./assets/productos-mundococteleria.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 450px;
}

/** CONTACTA **/
.bg-contacta {
  background-image: url(./assets/contacto-contacta.jpg);
}
.bg-proyecto {
  background-image: url(./assets/contacto-proyecto.jpg);
}
.bg-contacta-2 {
  background-image: url(./assets/foto-contacto.jpg);
  background-position: center !important;
  min-height: 500px !important;
}

.bg-contacta,
.bg-proyecto,
.bg-contacta-2 {
  background-position: 25% 25%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 650px;
}

/** DESCARGAS **/
.bg-descargas1 {
  background-image: url(./assets/descargas-1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  height: 130vh;
}
.bg-descargas2 {
  background-image: url(./assets/descargas-2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  height: 130vh;
}
.ico-download {
  width: 30px;
  margin-left: 10px;
  margin-bottom: 11px;
}

@media (max-width: 1460px) {
  .h1_50 {
    font-size: 40px !important;
  }
  .h1_55 {
    font-size: 42px !important;
  }
  .h1_75 {
    font-size: 62px !important;
  }

  .h2_40 {
    font-size: 32px !important;
  }
  .h2_43 {
    font-size: 37px !important;
  }
  .bg-contacta-2 {
    min-height: 420px !important;
  }
  .titlehist {
    font-size: 15px !important;
  }
}

@media (max-width: 1200px) {
  .h1_50 {
    font-size: 30px !important;
  }
  .h1_55 {
    font-size: 32px !important;
  }
  .h1_75 {
    font-size: 51px !important;
  }

  .h2_40 {
    font-size: 22px !important;
  }
  .h2_43 {
    font-size: 29px !important;
  }

  .txt-main {
    font-size: 28px;
    padding-right: 0px;
  }
  .bg-contacta-2 {
    min-height: 340px !important;
  }
}

.video-element {
  width: 100%;
  max-width: 100%;
}
.slide-image {
  height: 900px;
  background-size: cover;
}
.title-product {
  width: 50%;
  padding-right: 100px;
}
.link {
  text-decoration: none;
  color: black;
}
.link:hover {
  color: #e30613;
}

/* StoryLine */
.dot {
  font-size: 20px;
  margin-bottom: 10px;
  z-index: 10000;
}
.dot-container {
  position: relative;
  z-index: 2;
}
.dot-container:hover .dot {
  color: #dfdacd;
}
.blockhist {
  opacity: 0.4;
  transition: opacity 0.3s ease-in-out;
  overflow: hidden;
}
.blockhist-container {
  height: 700px;
  position: relative;
  overflow: visible;
}
.blockhist-container:hover .blockhist {
  opacity: 1;
  visibility: visible;
  max-height: 1000px;
}
.timeline-line {
  height: 5px;
  background-color: #000;
  transition: width 0.3s ease-in-out;
  position: absolute;
  top: 3.25%;
  left: 105%;
  transform: translateX(-50%);
  z-index: -1;
}

@media (max-width: 768px) {
  .logo {
    width: 190px;
  }
  .video-element {
    display: block;
    margin: auto;
  }
  .slide-image {
    max-width: 100%;
    display: block;
    margin: auto;
    height: 300px;
    background-position: center center;
    background-size: cover;
  }
  .search-input {
    margin-left: 0px;
  }
  .lang {
    position: absolute;
    right: 80px;
    top: 14px;
  }
  .bullets-ladestileria {
    font-size: 28px;
  }
  .bullets-ladestileria .number {
    margin-right: 40px;
    width: 30px;
  }
  .title-product {
    width: 100%;
    font-size: 22px;
  }
  .bgproductos {
    background-position: center left;
  }
  /****** MENU MOBILE */
  .closeButton {
    position: absolute;
    right: 10px;
    top: 0px;
    cursor: pointer;
    display: block !important;
    font-size: 40px;
  }
  .submundo-tequila {
    opacity: 1;
    display: block !important;
  }
  .submundo-cocteleria {
    opacity: 1;
    display: block !important;
  }
}

/****** MENU MOBILE */

.selectedPath {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selectedPath span {
  font-size: 12px;
}

.menu {
  transition: max-height 0.5s ease-in-out;
  overflow: auto;
  height: 800px;

  scrollbar-color: #e3dcd8 #ffffff;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e3dcd8;
    border-radius: 5px;
    border: 2px solid #c3c1bf;
  }
}

.map-container {
  position: relative;
  width: 80%;
  height: 0;
  padding-bottom: 56.25%;
}

.menu-closed {
  max-height: 4px !important;
  overflow: hidden;
}

@media (max-width: 768px) {
  .menu {
    max-height: 400px;
  }
}

.link-hovered {
  color: #d8ad89;
}

.link-active {
  color: #d8ad89;
}

.icohist {
  max-height: 160px;
}
.icoperson {
  max-width: 40px;
}
.titlehist {
  font-size: 20px;
  font-weight: bold;
  height: 20px;
}
.texthist {
  font-size: 15px;
}

/****** ANIMATIONS */
@-webkit-keyframes kenburns-bottom {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 84%;
    transform-origin: 50% 84%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(15px);
    transform: scale(1.25) translateY(15px);
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
  }
}
@keyframes kenburns-bottom {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 84%;
    transform-origin: 50% 84%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(15px);
    transform: scale(1.25) translateY(15px);
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
  }
}
@-webkit-keyframes kenburns-bottom-2 {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 84%;
    transform-origin: 50% 84%;
  }
  100% {
    -webkit-transform: scale(1.15) translateY(15px);
    transform: scale(1.15) translateY(15px);
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
  }
}
@keyframes kenburns-bottom-2 {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 84%;
    transform-origin: 50% 84%;
  }
  100% {
    -webkit-transform: scale(1.15) translateY(5px);
    transform: scale(1.15) translateY(5px);
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
  }
}

.slider-background {
  background-color: #e3dcd8;
}

/* Brands_Page */
.brand-image {
  height: 400px;
  background-size: cover;
  background-position: center;
  position: relative;
}

.product-carousel {
  padding: 20px;
}

/* INSTAGRAM */
.instagram-post {
  margin: 0 15px;
}

.card {
  width: 100%;
  border: none;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.card:hover {
  transform: scale(1.05);
}

.card-img-top {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.card-img-top:hover {
  filter: brightness(85%);
  transition: filter 0.3s ease-in-out;
}

.card-body {
  background-color: #f8f8f8;
  text-align: center;
}

.btn-primary {
  background-color: #385185;
  border: none;
}

.no-underline-black {
  color: black !important;
  text-decoration: none !important;
}

.no-underline-white {
  color: white !important;
  text-decoration: none !important;
}

/* FORMATS */
.img-format {
  transition: transform 0.3s ease;
  cursor: pointer;
  height: 120px !important;
  margin: 30px;
  transform: translateX(0%);
}

@media (max-width: 500px) {
  .img-format {
    transform: translateX(5%);
  }
}

.img-format:hover {
  transform: scale(1.1);
}

.border-warning {
  border-width: 2px !important;
}

.results-card {
  border-radius: 10px;
  border: 1px solid #e3dcd8;
  padding: 10px;
  margin: 10px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.results-card:hover {
  transform: scale(1.05);
}

.card-img-brand {
  max-width: 150px;
  max-height: 300px;
}

.card-img-product {
  width: 20%;
  object-fit: cover;
}

@media (max-width: 500px) {
  .card-img-product {
    max-width: 150px;
    max-height: 300px;
  }
}

@media (max-width: 1080px) {
  .card-img-product {
    max-width: 150px;
    max-height: 300px;
  }
}

@media (max-width: 992px) {
  .line {
    display: none;
  }

  .bg-contacta-2 {
    background-position: bottom !important;
  }
}

.modal-header {
  background-color: #2b373b;
  color: white;
}

.modal-title {
  font-weight: bold;
}

.modal-body {
  background-color: #f7f7f7;
}

.modal-text {
  font-size: 1.1rem;
  margin-bottom: 20px;
}

.age-container {
  display: block;
  margin-bottom: 20px;
}

.age-check {
  width: 1.5em;
  cursor: pointer;
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
}

.confirm-btn {
  background-color: #2b373b;
  border: none;
}

.confirm-btn:hover {
  background-color: #1a2426;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
}

/* Responsive Styles */
@media (max-width: 576px) {
  age-verification-modal {
    width: 80%;
    margin: 0 auto;
  }
  .age-check {
    width: 15px;
    height: 15px;
    transform: scale(1);
  }

  .modal-text {
    font-size: 0.9rem;
  }

  .modal-title {
    font-size: 1.2rem;
  }
}

.overlay-age {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
}

.custom-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1050;
}

.dialog {
  max-width: 500px;
  width: 100%;
}

.content-age {
  background-color: #dfdacd;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
}

.header-age,
.body-age,
.footer-age {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #dfdacd;
}

.centered-logo {
  width: 70%;
  display: block;
}

.btn-option {
  width: 160px;
  padding: 10px 0;
  border-radius: 1px;
  border: 1px solid #585650;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s;
}

.yes-btn {
  background-color: #585650;
  color: white;
}

.no-btn {
  background-color: #dfdacd;
  color: black;
}

.yes-btn:hover {
  background-color: #6b6b5a;
}

.no-btn:hover {
  background-color: #e2e1cf;
}

.body-age h4 {
  font-size: 1.5rem;
  font-family: "barlowregular", sans-serif;
  color: gray;
  margin-bottom: 20px;
}

.body-age {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.info-text {
  margin-top: 10px;
  text-align: center;
  color: #585650;
  font-size: 0.65rem;
}

.footer-age small {
  color: #585650;
}

.supertitle {
  font-size: 28px;
  font-weight: bold;
}

.skeleton-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
}

.skeleton-grid .react-loading-skeleton {
  border-radius: 4px;
  color: #e1e1e1;
}

.youtube-background {
  position: relative;
  overflow: hidden;
  width: 225vh;
  height: 125vh;
}

.youtube-background iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.line {
  position: absolute;
  height: 3px;
  width: 104%;
  background-color: black;
  top: 8px;
  left: 50%;
}

.kitdigital {
  top: 0;
  right: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
